import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
    SectionHeading,
    SectionBlockEmbedCalendar,
} from "../styledComponents/section"

const Heading = ({ level = 1, location, children, align }) => (
    <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
        {children}
    </SectionHeading>
)

const QuantumBrainHealingScheduleAppointments = ({ location }) => {
    return (
        <>
            <Helmet>
                <meta name={`robots`} content={`noindex, nofollow`} />
            </Helmet>
            <Layout location={location} hideNewsletterSignUp>
                <SectionBlockEmbedCalendar noMargin>
                    <Heading level={1} align="center">
                        Schedule an appointment
                    </Heading>
                    <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3XnWlMD4ZU6KGkCPBGrRHj8arJgElRxaWoIylhHZahOZDjFW8d8rnlkQPZFPHR9xHLT7Bi01Ge?gv=true" width="100%" style={{'width':'100%!important'}} height="600" frameborder="0"></iframe>
                </SectionBlockEmbedCalendar>
            </Layout>
        </>
    )
}

export default QuantumBrainHealingScheduleAppointments